import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";
import { IInComingRequestResponse, ISubmittedOfferResponse } from "../type";

export const incomingRequest = createApi({
    reducerPath: "incomingRequest",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["create-offers"], 

    endpoints: (builder) => ({
        getIncomingRequest: builder.query({
            query: () => ({
                url: "incoming-request",
                method: "GET",
            }),
            transformResponse: (response: IInComingRequestResponse[]) => response,
        }),
        getIncomingRequestDetail: builder.query({
            query: (id) => ({
                url: `incoming-request/${id}`,
                method: "GET",
            }),
        }),
        getOffersSentToRequester: builder.query({
            query: () => ({
                url: "offers/sent-to-requester",
                method: "GET",
            }),
        }),
        createOffer: builder.mutation({
            query: (data: any) => ({
                url: "offers",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["create-offers"],

        }),
        submittedOffer: builder.query({
            query: ({ id, conversationId }) => `offers/details?request_id=${id}&conversation_id=${conversationId}`,
            transformResponse: (response: ISubmittedOfferResponse) => response.data,
            providesTags: ["create-offers"]

        }),
    }),
});

export const {
    useGetIncomingRequestQuery,
    useGetOffersSentToRequesterQuery,
    useCreateOfferMutation,
    useGetIncomingRequestDetailQuery,
    useSubmittedOfferQuery
} = incomingRequest;
