import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Flowbite, Spinner } from "flowbite-react";
import theme from "./flowbite-theme";
import { store } from './store';
import { Provider } from 'react-redux';

// Lazy load the components
const Login = React.lazy(() => import('./auth/pages/Login'));
const Register = React.lazy(() => import('./auth/pages/Register'));
const RegisterForCompany = React.lazy(() => import('./auth/pages/Register/RegisterForCompany'));

const TeamPage = React.lazy(() => import('./main/pages/Teams'));
const TeamDetailPage = React.lazy(() => import('./main/pages/Teams/TeamDetail'));
const IncomingRequests = React.lazy(() => import('./main/pages/IncomingRequests'));
const IncomingRequestDetail = React.lazy(() => import('./main/pages/IncomingRequests/IncomingRequestDetail'));
const ForgotPassword = React.lazy(() => import('./auth/pages/ForgotPassword'));
const Profile = React.lazy(() => import('./main/pages/Profile'));
const PasswordReset = React.lazy(() => import('./auth/pages/PasswordReset'));
const ResendEmail = React.lazy(() => import('./auth/pages/ResendEmail'));
const Home = React.lazy(() => import('./main/pages/Home'));
// const ChatPage = React.lazy(() => import('./main/pages/Chat'));
const Categories = React.lazy(() => import('./main/pages/categories'));
const MyRequest = React.lazy(() => import('./main/pages/MyRequest'));
const DashBoardPage = React.lazy(() => import('./main/pages/Dashboard'));
const RequestDetail = React.lazy(() => import('./main/pages/MyRequest/RequestDetail'));

const ProtectedRoute = React.lazy(() => import('./ProtectedRoute'));
const ProtectedRouteDashBoard = React.lazy(() => import('./ProtectedRouteDashBoard'));
const ProtectedRouteUUIDGuest = React.lazy(() => import('./ProtectedRouteUUIDGuest'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Flowbite theme={{ theme }}>
        <Router>
          <Suspense fallback={
            <div className="flex justify-center items-center" style={{ minHeight: '100%' }}>
              <Spinner aria-label="Loading category data" size="xl" />
            </div>
          }
          >
            <Routes>
              <Route path="/login" element={
                <ProtectedRoute>
                  <Login />
                </ProtectedRoute>
              } />
              <Route path="/register-for-user" element={<ProtectedRoute><Register /> </ProtectedRoute>} />
              <Route path="/register-for-company" element={<ProtectedRoute><RegisterForCompany /> </ProtectedRoute>} />
              <Route path="/forgot-password" element={<ProtectedRoute><ForgotPassword /></ProtectedRoute>} />
              <Route path="/password/reset" element={<ProtectedRoute><PasswordReset /></ProtectedRoute>} />
              <Route path="/resend-email" element={<ProtectedRoute><ResendEmail /></ProtectedRoute>} />

              <Route path="/" element={<ProtectedRouteUUIDGuest><Home /></ProtectedRouteUUIDGuest>} />
              <Route path="/categories/:id" element={<ProtectedRouteUUIDGuest><Categories /></ProtectedRouteUUIDGuest>} />
              <Route path="/my-requests" element={<MyRequest />} />
              <Route path="/dashboard" element={<DashBoardPage />} />
              <Route path="/my-requests/:id" element={<ProtectedRouteDashBoard><RequestDetail /></ProtectedRouteDashBoard>} />
              <Route path="/teams" element={<ProtectedRouteDashBoard><TeamPage /></ProtectedRouteDashBoard>} />
              <Route path="/team/:id" element={<ProtectedRouteDashBoard><TeamDetailPage /></ProtectedRouteDashBoard>} />
              {/* <Route path="/chat" element={<ProtectedRouteDashBoard><ChatPage /></ProtectedRouteDashBoard>} /> */}
              <Route path="/incoming-requests" element={<ProtectedRouteDashBoard><IncomingRequests /></ProtectedRouteDashBoard>} />
              <Route path="/profile" element={<ProtectedRouteDashBoard><Profile /></ProtectedRouteDashBoard>} />
              <Route path="/incoming-requests/:id" element={<ProtectedRouteDashBoard><IncomingRequestDetail /> </ProtectedRouteDashBoard>} />
            </Routes>
          </Suspense>
        </Router>
      </Flowbite>
    </Provider>
  </React.StrictMode>
);
