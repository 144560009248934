import { Middleware } from "@reduxjs/toolkit";
import { authApi } from "../../services/authApi";
import Cookies from 'js-cookie';

export const authMiddleware: Middleware = ({ dispatch, getState }) => next => (action: any) => {
  if (authApi.endpoints.login.matchFulfilled(action)) {
    const { access_token, refresh_token, user } = action.payload;
    Cookies.set('access_token', access_token, { expires: 1 }); 
    Cookies.set('refresh_token', refresh_token, { expires: 1 }); 
    Cookies.set('email', user.email, { expires: 1 }); 
  }

  if (authApi.endpoints.refreshToken.matchFulfilled(action)) {
    const { access_token } = action.payload;

    Cookies.set('access_token', access_token, { expires: 1 });
  }

  return next(action);
};
