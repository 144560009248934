import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import { authApi } from "../services/authApi";
import { meApi } from "../services/meApi";
import { authMiddleware } from "./middleware/authMiddleware";
import appSlice, { AppState } from "./app/appSlice";
import { categories } from "../services/categoriesApi";
import { myRequestApi } from "../services/myRequestApi";
import { incomingRequest } from "../services/incomingRequest";
import { teamApi } from "../services/teamApi";

export const store = configureStore({
  reducer: {
    auth: authSlice, 
    app: appSlice,    
    [authApi.reducerPath]: authApi.reducer, 
    [meApi.reducerPath]: meApi.reducer, 
    [categories.reducerPath]: categories.reducer, 
    [myRequestApi.reducerPath]: myRequestApi.reducer, 
    [incomingRequest.reducerPath]: incomingRequest.reducer, 
    [teamApi.reducerPath]: teamApi.reducer, 

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(teamApi.middleware, incomingRequest.middleware, authApi.middleware, meApi.middleware, myRequestApi.middleware, categories.middleware, authMiddleware),  
});

export type RootState = {
  auth: ReturnType<typeof authSlice>;
  app: AppState;
  [authApi.reducerPath]: ReturnType<typeof authApi.reducer>;
  [meApi.reducerPath]: ReturnType<typeof meApi.reducer>;
  [categories.reducerPath]: ReturnType<typeof categories.reducer>;
  [myRequestApi.reducerPath]: ReturnType<typeof myRequestApi.reducer>;
  [incomingRequest.reducerPath]: ReturnType<typeof incomingRequest.reducer>;
};

export type AppDispatch = typeof store.dispatch;
