import Cookies from "js-cookie";

export const categoriesHeader = (): any => {
  return {
    baseUrl: process.env.REACT_APP_API_BASE_URL, 
    prepareHeaders: (headers: Headers) => {
      const token = Cookies.get("access_token");
      const guest_UUID = Cookies.get("guest_uuid");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      } else if (guest_UUID && guest_UUID !== "undefined" && guest_UUID !== "") {
        headers.set("Authorization",  `Bearer ${guest_UUID}`); 
      }
      return headers;
    },
  };
};
