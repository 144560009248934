import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface IAuthState {
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;

}
const INITIAL_STATE = {
  email: null,
  access_token: null,
  refresh_token: null

} as IAuthState;

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setCredentials: (state: IAuthState, action: PayloadAction<IAuthState>) => {
      const { email, access_token, refresh_token } = action.payload;
      state.email = email;
      state.access_token = access_token;
      state.refresh_token = refresh_token;

    },
    logOut: (state: IAuthState) => {
      state.email = null;
      state.access_token = null;
      state.refresh_token = null;

    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentEmail = (state: RootState) => state!.auth.email;
export const selectCurrentToken = (state: RootState) => state!.auth.access_token;