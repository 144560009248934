import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";

export const meApi = createApi({
    reducerPath: "meApi",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["Me"],
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => "/user/me",
            providesTags: ["Me"],
        }),
        updateUser: builder.mutation({
            query: ({
                phone_number,
                first_name,
                last_name,
                born_at,
                address,
                country,
                state,
                city,
                postcode,
            }) => ({
                url: "user/update",
                method: "PUT",
                body: {
                    phone_number,
                    first_name,
                    last_name,
                    born_at,
                    address,
                    country,
                    state,
                    city,
                    postcode,
                },
            }),
            invalidatesTags: ["Me"],
        }),
        updatePassword: builder.mutation({
            query: ({ current_password, new_password, new_password_confirmation }) => ({
                url: "user/update-password",
                method: "POST",
                body: {
                    current_password,
                    new_password,
                    new_password_confirmation,
                },
            }),
        }),
        uploadAvatar: builder.mutation({
            query: (formData: FormData) => ({
                url: "user/avatar",
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Me"],
        }),
    }),
});

export const {
    useGetMeQuery,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useUploadAvatarMutation,
} = meApi;
