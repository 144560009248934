import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";
import { IConversationResponse, IMessageResponse, IMyRequestResponse, IOfferResponse, IOffersByRequestAndCompanyResponse, IRequestResponse, IUserForCompanyResponse } from "../type";

export const myRequestApi = createApi({
    reducerPath: "myRequestApi",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["my-request", "offers","conversation", "message"], 
    endpoints: (builder) => ({
        myRequests: builder.query({
            query: () => "request/my-requests",
            providesTags: ["my-request"],
            transformResponse: (response: IMyRequestResponse) => response.data
        }),
        allOffersOfRequest: builder.query({
            query: ({ id }) => `offers/all?request_id=${id}`,
            transformResponse: (response: IOfferResponse) => response.data,
            providesTags: ["offers"]
        }),
        request: builder.query({
            query: ({ id }) => `request/${id}`,
            transformResponse: (response: IRequestResponse) => response.data
        }),
        updateRequest: builder.mutation({
            query: ({ id, requestData }: { id: number; requestData: any }) => ({
                url: `request/${id}`,
                method: 'PUT',
                body: requestData,
            }),
            invalidatesTags: ["my-request"],
        }),
        deleteRequest: builder.mutation({
            query: (id: number) => ({
                url: `request/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["my-request"],
        }),
        myConversation: builder.query({
            query: ({ id }) => `conversation/${id}`,
            providesTags: ["conversation"],
            transformResponse: (response: IConversationResponse) => response.data
        }),
        userForCompany: builder.query({
            query: ({ id }) => `companies/${id}/users`,
            transformResponse: (response: IUserForCompanyResponse[]) => response
        }),
        message: builder.query({
            query: ({ company_id,request_id }) => `message/request?company_id=${company_id}$&request_id=${request_id}`,
            providesTags: ["message"],
            transformResponse: (response: IMessageResponse) => response.data
        }),
        sendMessage: builder.mutation({
            query: (messageData) => ({
                url: "message/send-message",
                method: "POST",
                body: messageData,
            }),
            invalidatesTags: ["message"],
        }),
        updateConversationStatus: builder.mutation({
            query: ({ id, statusData }: { id: string; statusData: any }) => ({
                url: `conversation/${id}/status`,
                method: 'PUT',
                body: statusData,
            }),
            invalidatesTags: ["conversation"],
        }),
        updateOfferStatus: builder.mutation({
            query: ({ id, statusData }: { id: number; statusData: any }) => ({
                url: `offers/update-status/${id}`,
                method: 'PUT',
                body: statusData,
            }),
            invalidatesTags: ["offers"],
        }),
        offersByRequestAndCompany: builder.query({
            query: ({ requestId, companyId }) => `offers/by-request-and-company?request_id=${requestId}&company_id=${companyId}`,
            transformResponse: (response: IOffersByRequestAndCompanyResponse) => response.data,
            providesTags: ["offers"],
        }),

    }),
});

export const {
    useRequestQuery,
    useMyRequestsQuery,
    useUpdateRequestMutation,
    useAllOffersOfRequestQuery,
    useDeleteRequestMutation,
    useMyConversationQuery,
    useUpdateConversationStatusMutation,
    useUpdateOfferStatusMutation,
    useOffersByRequestAndCompanyQuery,
    useUserForCompanyQuery,
    useMessageQuery,
    useSendMessageMutation
} = myRequestApi;
