import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";

export const teamApi = createApi({
    reducerPath: "teamApi",
    baseQuery: fetchBaseQuery(authHeader()),
    tagTypes: ["team", "team-detail"],

    endpoints: (builder) => ({
        getAllTeamsInCompany: builder.query({
            query: () => ({
                url: "team",
                method: "GET",
            }),
            providesTags: ["team"],
        }),
        createTeam: builder.mutation({
            query: (data: any) => ({
                url: "team",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team"],
        }),
        deleteTeam: builder.mutation({
            query: (id: number) => ({
                url: `team/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["team"],
        }),


        getTeamDetail: builder.query({
            query: (id: any) => ({
                url: `team/${id}`,
                method: "GET",
            }),
            providesTags: ["team-detail"],
            transformResponse: (response: any) => response?.data
        }),
        getAllUserUnassign: builder.query({
            query: () => ({
                url: 'user/unassigned',
                method: "GET",
            }),
            providesTags: ["team-detail"],
            transformResponse: (response: any) => response?.data
        }),
        addUser: builder.mutation({
            query: (data: any) => ({
                url: "team/add-users",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["team-detail"],
        }),
        deleteUserFromTeam: builder.mutation({
            query: (data: any) => ({
                url: 'team/remove-user',
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: ["team-detail"],
        }),

    }),
});

export const {
    useGetAllTeamsInCompanyQuery,
    useCreateTeamMutation,
    useDeleteTeamMutation,
    useGetTeamDetailQuery,
    useGetAllUserUnassignQuery,
    useAddUserMutation,
    useDeleteUserFromTeamMutation
} = teamApi;
