import Cookies from "js-cookie";

export const authHeader = (): any => {
  return {
    baseUrl: process.env.REACT_APP_API_BASE_URL, 
    // credentials: "omit",
    prepareHeaders: (headers: Headers) => {
      const token = Cookies.get("access_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  };
};
