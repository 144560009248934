import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategoryResponse, UserResponse } from '../../type';
export interface AppState {
  user: UserResponse | null;
  isAuthenticated: boolean;
  categories: ICategoryResponse | null;  
}

const initialState: AppState = {
  user: null,
  isAuthenticated: false,
  categories: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setCategories: (state, action: PayloadAction<ICategoryResponse>) => {
      state.categories = action.payload; 
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, clearUser, setCategories } = appSlice.actions;
export default appSlice.reducer;
