import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "./header/authHeader";

// Building api for authentication
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery(authHeader()),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (userDetails) => ({
        url: "auth/register",
        method: "POST",
        body: { ...userDetails },
      }),
    }),
    refreshToken: builder.mutation({
      query: (refreshToken) => ({
        url: "auth/refresh-token",
        method: "POST",
        body: { refresh_token: refreshToken },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ email, token, password, password_confirmation }) => ({
        url: "auth/password/reset",
        method: "POST",
        body: {
          email,
          token,
          password,
          password_confirmation,
        },
      }),
    }),
    forgotPasswordEmail: builder.mutation({
      query: ({ email }) => ({
        url: "auth/password/email",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    resendVerification: builder.mutation({
      query: ({ email }) => ({
        url: "auth/resend-verification",
        method: "POST",
        body: { email },
      }),
    }),
    
    
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRefreshTokenMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useForgotPasswordEmailMutation,
  useResendVerificationMutation,
} = authApi;
